
import { USER } from "../../store/state";
import { applyPermission, createDoc, getDocDetails, getDocTitle, updateDoc, updateShareDoc } from "@/api/request-modules/document";
import { useRouter } from "vue-router";
import { listenFullScreen, toggleFullScreen } from "@/utils/index";
import copyField from "@/utils/copyField";
import useDocument, { DocDetails, onUpdateCollection, onDeleteDoc, randomStringId, ParamsUpdateDocTitleInter, TagItemInter } from "@/composables/useDocument";
import { defineComponent, reactive, onMounted, ref, watch } from "vue";
import { ResponseParams } from "@/types/response";
import { setSession, getSession } from "@/utils";
import Notices from "@/components/notices/index.vue";
import Sidebar from "./components/sidebar.vue";
import { getShortCode, shortCode } from "@/api/request-modules/document";
import qs from "qs";
import { CLASSIFY, LINKSHARE, PUBLICSHARE } from "@/utils/index";
import { ElMessage } from "element-plus";
import ShareClassify from "./components/shareClassify.vue";
import { UploadFile } from "@/types/upload";
import { Bell } from "@element-plus/icons";
import HandlePerson from "@/components/handle-person/handle-person.vue";
import { TestBillNotifyItem } from "@/composables/useTestBill";
import { systemBehaviorStore } from "@/utils/contantOptions";

interface Members {
  id: number;
  name: string;
  uuid: string;
  index: number;
  color: string;
  userId?: string;
}

export default defineComponent({
  name: "padIframe",
  components: {
    Notices,
    Sidebar,
    ShareClassify,
    Bell,
    HandlePerson
  },
  data() {
    return {
      notices: 0
    };
  },
  setup() {
    const locat = window.location;
    const isPro = locat.host.includes("tomato.petrvet.com") ? true : false;
    const iframeHost = (isPro ? "//online-editor.petrvet.com" : "//test.online-editor.rvet.cn") + `?t=${Date.now()}`;
    let content_id = ref(randomStringId()); // 编辑器文章id
    const router = useRouter();
    const articleId = ref(0); // 文章id
    let isShowIframe = ref(false); // 是否显示编辑器iframe
    let title = ref(""); // 文章title
    let permission = ref(0); // 0 无权限，1，可阅读，2 可编辑
    let status = ref(1); // 文档状态
    let isShowContainer = ref(false); // 是否渲染编辑器
    let hasPermissOpt = ref(true); // 是否操作权限按钮
    let creator = ref(""); // 创建者
    const visible = ref(false);
    const onlineMembers = ref<Members[]>([]);
    let needSend = true; // 是否需要发送消息到编辑器
    const sidebar = ref(); // sidebar ref
    const shareLoading = ref(false); // 分享dialog loading
    const columnShareList = ref(); // 分类列表
    const isShareSidebar = ref(true); // 是否显示sidebar
    const tempObj = reactive({
      id: 0,
      department: [],
      participant: []
    });
    let docDetails: DocDetails = {
      comment_permission: 0,
      content_id: "",
      create_by: "",
      creator: "",
      id: 0,
      is_collection: 0,
      link_share: 0,
      open_share: 0,
      permission: 0,
      status: 0,
      title: ""
    };
    const dropList: { id: number; label: string }[] = reactive([
      {
        id: 0,
        label: "新窗口打开"
      },
      {
        id: 1,
        label: "复制链接"
      },
      {
        id: 2,
        label: "收藏"
      },
      {
        id: 3,
        label: "导出所有文档为PDF"
      },
      {
        id: 4,
        label: "删除"
      },
      {
        id: 5,
        label: "文档权限"
      }
      // {
      //   id: 6,
      //   label: "历史记录"
      // }
    ]);
    // 分享弹框参数
    const formParams: any = reactive({
      root_classify_id: "",
      id: 57,
      status: 1,
      content_id: "",
      classify_id: "",
      comment_permission: "1",
      link_share: "1",
      open_share: "1",
      tag_id: [],
      department: [],
      team_worker: [],
      title: ""
    });

    const { useCreateChildren, useGetShareList, useGetTagList } = useDocument();

    // 控制消息框显示隐藏
    const show = (params?: string) => {
      if (params === "show") visible.value = true;
      else visible.value = !visible.value;
    };

    // 发送消息给Pad编辑器
    const connectWithPad = (name = "tomato", data?: any) => {
      let padIframe = document.getElementById("padIframe");
      const isIFrame = (input: HTMLElement | null): input is HTMLIFrameElement => input !== null && input.tagName === "IFRAME";
      if (padIframe) {
        if (isIFrame(padIframe) && padIframe.contentWindow) {
          padIframe?.contentWindow.postMessage(
            {
              name,
              data
            },
            "*"
          );
        }
      }
    };

    // 复制链接
    const copyLink = async () => {
      const currentLink = getSession("shortCode", true) as any;
      if (!currentLink) return;
      if (currentLink.content.includes("is_link_share")) {
        copyField(window.location.origin + "/document/padIframe?" + currentLink.code);
      } else {
        // const url = href.indexOf("content_id") > -1 ? href + "&is_link_share=1" : `${href}?docId=${articleId.value}&content_id=${content_id.value}&is_link_share=1`;
        const url = currentLink.content.indexOf("content_id") > -1 ? currentLink.content + "&is_link_share=1" : currentLink.content;
        await transformAddShortCode(url, true);
      }
    };

    const onDownload = (type: string, title: string) => {
      connectWithPad("download", {
        type,
        title
      });
    };

    // 新建标题
    const createDocTitle = async (title: string) => {
      const { data } = await createDoc({ title, content_id: content_id.value });
      return data;
    };

    // 更新标题
    const updateDocTitle = async (title: string, content_id: string, id: number) => {
      const { data } = await updateDoc({ title, id, content_id });
      formParams.title = title;
      document.title = docDetails.title;
      return data;
    };

    // 更新文档title
    const onUpdateDocTitle = () => {
      if (!title.value) return ElMessage.warning("请填写标题");
      updateDocTitle(title.value, content_id.value, articleId.value);
    };

    // 获取文档详情
    const onGetDocDetail = async (id: number) => {
      const { data } = await getDocDetails(id);
      return data;
    };

    // 申请文档权限
    const onApplyPermission = async (type: number) => {
      const { code } = await applyPermission({ type, wiki_id: articleId.value });
      if (code === 200) {
        ElMessage({
          type: "success",
          message: "申请成功"
        });
      }
    };

    // 切换操作收藏文本
    const handleCollectionValue = () => {
      const n = dropList.find((i) => i.id === 2);
      n!.label = docDetails.is_collection !== 2 ? "取消收藏" : "收藏";
    };
    let num = 0;
    // 分享弹框点击请求数据分享文章
    const shareArtical = async () => {
      return await updateShareDoc(formParams);
    };

    // 二级分类
    // let classifyChildren = ref<TransformTabList[]>([]);
    let classifyTag = ref<TagItemInter[]>([]);
    // 添加协作者
    const dialogShadow = ref(false);
    const filterText = ref();
    const dialogVisible = ref(false);
    // 协作者人员列表列表
    let selectPerson = ref<Array<Record<string, any>>>([]);

    const comment_permission = ref(false);
    const link_share = ref(false);
    const open_share = ref(false);
    // 监听标注评论
    watch(
      () => comment_permission.value,
      (newVal) => {
        if (num) {
          if (newVal) {
            formParams.comment_permission = "1";
          } else {
            formParams.comment_permission = "0";
          }
          shareArtical();
        }
      }
    );
    // 监听链接分享
    watch(
      () => link_share.value,
      (newVal) => {
        if (num) {
          if (newVal) {
            formParams.link_share = "1";
          } else {
            formParams.link_share = "0";
          }
          shareArtical();
        }
      }
    );
    // 监听公开分享
    watch(
      () => open_share.value,
      (newVal) => {
        if (num) {
          if (newVal) {
            formParams.open_share = "1";
          } else {
            formParams.open_share = "0";
          }
          shareArtical();
        }
      }
    );

    // 协作者选中列表id
    const keywordTreeRef = ref();

    // 分享
    const handleShare = async () => {
      dialogVisible.value = true;
      hasPermissOpt.value = false;
      await getShareColumnList();
      if (!classifyTag.value.length) {
        await getTagList();
      }
      setTimeout(() => {
        num = 1;
      }, 100);
    };

    watch(
      () => filterText.value,
      (newValue) => {
        keywordTreeRef?.value.filter(newValue);
      }
    );

    // 添加协作者
    const handleAddPeople = async () => {
      dialogShadow.value = true;
    };

    // 添加成员回调
    const onSubmit = async (info: { department: number[]; team_worker: string[]; selectPerson: TestBillNotifyItem[] }) => {
      const { selectPerson: _selectPerson, department, team_worker } = info;
      const team_worker_temp = JSON.parse(JSON.stringify(formParams.team_worker));
      const department_temp = JSON.parse(JSON.stringify(formParams.department));
      formParams.team_worker = team_worker;
      formParams.department = department;
      const data = await shareArtical();
      // @ts-ignore
      if (data.code !== 200) {
        formParams.team_worker = team_worker_temp;
        formParams.department = department_temp;
        return;
      }
      // @ts-ignore
      tempObj.department = department;
      // @ts-ignore
      tempObj.participant = _selectPerson;
      selectPerson.value = _selectPerson;
    };

    // 获取文章详情赋值给分享弹出formParams
    const setShareParams = (docDetails: Record<string, any>) => {
      formParams.team_worker = [];
      formParams.department = [];
      const { id, status, classify_id, content_id, tag_ids, title, team_worker, department, root_classify_id } = docDetails;

      // 分享字段赋值
      formParams.id = id;
      formParams.status = status;
      formParams.classify_id = classify_id || "";
      formParams.root_classify_id = classify_id ? root_classify_id + "" : "";
      formParams.content_id = content_id;
      formParams.comment_permission = docDetails.comment_permission + "";
      formParams.link_share = docDetails.link_share + "";
      formParams.open_share = docDetails.open_share + "";
      formParams.tag_id = tag_ids;
      formParams.title = title;
      team_worker.forEach((item: Record<string, any>) => {
        formParams.team_worker.push(item.staff_no);
      });
      department.forEach((item: Record<string, any>) => {
        formParams.department.push(item.staff_no * 1);
      });

      // 分享字段赋值
      comment_permission.value = docDetails.comment_permission ? true : false;
      link_share.value = docDetails.link_share ? true : false;
      open_share.value = docDetails.open_share ? true : false;
      const allSelect = [...team_worker, ...department];
      selectPerson.value = allSelect;
    };

    // 获取文章的title
    const onGetDocTitle = async (content_id: string) => {
      const { data } = await getDocTitle(content_id);
      return data;
    };

    const handleMenuItem = async (item: Record<string, any>) => {
      if (item.id > 2 && permission.value !== 2) return;
      switch (item.id) {
        case 0:
          // 新窗口打开
          window.open(locat.href, "_blank");
          break;
        case 1:
          // 复制连接
          copyLink();
          break;
        case 2:
          // 收藏、取消收藏
          {
            const t = docDetails.is_collection === 1 ? 2 : 1;
            const isSucc = await onUpdateCollection(t, articleId.value);
            if (!isSucc) return;
            docDetails.is_collection = t;
            // 处理收藏和取消收藏文案
            handleCollectionValue();
          }
          break;
        case 3:
          // 导出文档为PDF
          // 发送消息给pad端
          localStorage.setItem("tree", JSON.stringify(sidebar.value.menuData));
          connectWithPad("tree", { tree: JSON.stringify(sidebar.value.menuData), title: title.value });
          break;
        case 4:
          // 删除
          onDeleteDoc(articleId.value, title.value);
          break;
        case 5:
          // 权限
          setTimeout(() => {
            num = 1;
          }, 100);
          dialogVisible.value = true;
          hasPermissOpt.value = true;
          break;
        case 6: // 历史记录
          break;
        default:
          console.log(1);
      }
    };
    /**
     * 返回功能分好几种情况处理
     * 1. 正常就是跳转到 documentCenter
     * 2. 如果是带origin参数，就跳到origin 指定的路由
     * 3. 如果iframeUrl 含有timeslider参数，说明目前是浏览记录页面，需要会到当前编辑页
     */
    const goBack = () => {
      // 默认
      let name = "documentCenter";
      name = (getSession("defaultActiveTab") as string) || "documentCenter";
      router.push({
        name,
        replace: true,
        query: {
          type: "docBack"
        }
      });
    };
    // 新增文档时链接转码  params参数   isCopy生成后是否进行复制  type: 判断url是不是旧的，即之前的版本(未生产短链)分享出去的链接 type=newArtical代表是新增的文档
    const transformAddShortCode = async (params: string, isCopy?: boolean, type?: string) => {
      shortCode<ResponseParams.ResponseDataSuccess>({ content: params }).then((res) => {
        if (res.code === 200) {
          // 保存转链后的信息
          setSession("shortCode", JSON.stringify(res.data));
          if (type) {
            if (type === "newArtical") {
              window.history.replaceState({}, "", `/document/padIframe?${res.data.code}`);
            } else {
              window.location.href = window.location.origin + "/document/padIframe?" + res.data.code;
              location.reload();
            }
          }
          if (isCopy) {
            copyField(window.location.origin + "/document/padIframe?" + res.data.code);
          }
        }
      });
    };
    // 链接解码
    const transformShortCode = async () => {
      let localUrl = window.location.href.split("?")[1];
      const { data } = await getShortCode({ id: localUrl });
      return data;
    };

    onMounted(async () => {
      // 判断链接中是否存在docId, 此处的判断主要是解决之前旧版本分享出去的文章链接（未转链的）转链成功后进行再次拿到锻炼的url刷新页面
      if (window.location.href.includes("docId")) {
        const hrefUrl = window.location.href.split("?")[1];
        transformAddShortCode(hrefUrl, false, "oldUrl");
        return;
      }
      // 获取短链信息
      const data = await transformShortCode();
      const linkData = qs.parse(data.content) as Record<string, any>;
      // contentId 默认为create时的本地创建的id
      if (!linkData.docId && !linkData.content_id) {
        // 初始化调用后台接口
        const { id } = await createDocTitle("");
        articleId.value = id;
        const a = "docId=" + id + "&content_id=" + content_id.value;
        // 拿到新增的文章id进行转换成短链接
        transformAddShortCode(a, false, "newArtical");
        // 重新跳转下
      } else {
        articleId.value = parseInt(linkData.docId, 10);
        // 如果contentid存在，表示位编辑，就用当前的contentid
        content_id.value = linkData.content_id;
        // 保存转链后的信息
        setSession("shortCode", JSON.stringify(data));
      }

      // 获取此文章的一些信息、如权限等
      docDetails = await onGetDocDetail(articleId.value);
      tempObj.id = docDetails.id;
      // @ts-ignore
      tempObj.department = docDetails.department;
      // @ts-ignore
      tempObj.participant = docDetails.team_worker;
      permission.value = docDetails.permission;
      status.value = docDetails.status;
      creator.value = docDetails.creator;
      /**
       * 这里写在判断外面是不管它是编辑or只读权限，先把iframe加载出来
       */
      // iframeUrl.value += content_id.value;
      if (permission.value === 2) {
        isShowIframe.value = true;
      }
      await setShareParams(docDetails);

      // 获取文档权限状态

      if (docDetails.is_collection === 1) {
        handleCollectionValue();
      }

      // 获取doc title
      onGetDocTitle(content_id.value).then(({ title: docTitle }) => {
        title.value = docTitle;
        formParams.title = docTitle;
        document.title = docDetails.title;
      });

      isShowContainer.value = true;
      // 定时发送消息给editor
      sendMsgToEditor();
      // 监听收到的消息
      window.addEventListener("message", (e) => {
        const { name, onlineMembers: d } = e.data;
        const onlineMem = Array.isArray(d) && d.filter((item: Record<string, any>) => item.name);

        // 收到成员消息更新
        if (name === "updateMembers") {
          let obj: { [key: string]: any } = {};
          const newArr =
            Array.isArray(onlineMem) &&
            onlineMem.reduce((prev, cur) => {
              obj[cur.userId] ? "" : (obj[cur.userId] = true && prev.push(cur));
              return prev;
            }, []);

          // 过滤掉自己
          onlineMembers.value = newArr;
        } else if (name === "getTomatoSuccess") {
          // 如果收到editor的消息，tomato就不需要再发送消息给用editor了
          needSend = false;
        }
      });
    });

    const session = getSession(systemBehaviorStore.systemUserinfo, true) as USER;
    const sendMsgToEditor = () => {
      setTimeout(() => {
        connectWithPad("tomato", {
          contentId: content_id.value,
          token: session?.token,
          permission: permission.value,
          docId: articleId.value,
          title: title.value,
          name: session?.name,
          userId: session?.userid
        });

        if (needSend) {
          sendMsgToEditor();
        }
      }, 500);
    };

    // 全屏
    const toggleEditorFullScreen = () => {
      // 处理全屏
      listenFullScreen((isClose: boolean) => {
        // 显示文档树
        isShareSidebar.value = !isClose;
        // 关闭全屏
        connectWithPad("fullScreen", { fullScreen: isClose });
      });
      // 通知编辑器，全屏
      toggleFullScreen();
      // 隐藏文档树
      isShareSidebar.value = false;
      connectWithPad("fullScreen", { fullScreen: !isShareSidebar.value });
    };
    // type: number, item: TreeItem | any, uploadFile?: UploadFile | any
    interface EventData {
      uploadFile?: UploadFile;
      content_id?: string;
      parent_id?: number;
      label?: string;
      id?: number;
      title?: string;
      data?: any;
    }
    // 侧边栏 sidebar事件
    const onEvent = async (params: { type: number; data: EventData; cb: (...rest: any) => void }) => {
      const { type, data = {}, cb } = params;
      const { uploadFile, content_id: contentId, data: d, parent_id, title } = data;

      if (type === 1 || type === 4) {
        // 新建子文档和导入子文档
        content_id.value = randomStringId();

        const param = {
          title: title!,
          parent_id: parent_id!,
          content_id: content_id.value
        };
        if (type === 4 && uploadFile?.name) {
          param.title = uploadFile.name.replace(/\.docx/g, "") ?? "未命名文档";
        }

        const data = await createChildren(param);
        if (data) {
          // 发送消息给编辑器
          connectWithPad("changeDoc", {
            contentId: content_id.value,
            token: session?.token,
            permission: permission.value,
            file: type === 4 ? uploadFile : null
          });
          // 更新sidebar 数据
          sidebar.value?.getTreeList();
          // 执行回调
          if ((type === 1 || type === 4) && typeof cb === "function") cb(data.id);
        }
      } else if (type === 2) {
        // 切换文档
        content_id.value = contentId!;
        // 发送消息给编辑器
        connectWithPad("changeDoc", {
          contentId: content_id.value,
          token: session?.token,
          permission: permission.value
        });
      } else if (type === 3) {
        // 下载pdf
        onDownload("pdf", d.label);
      } else if (type === 5) {
        // 更新到主文档下面
        // 发送消息给编辑器
        connectWithPad("changeDoc", {
          contentId: docDetails.content_id,
          token: session?.token,
          permission: permission.value
        });
      }
    };

    // 更新title
    const createChildren = async (params: Omit<ParamsUpdateDocTitleInter, "id">) => {
      return await useCreateChildren(params);
    };

    // 获取分享分类list
    const getShareColumnList = async () => {
      shareLoading.value = true;
      const data = await useGetShareList();
      shareLoading.value = false;
      columnShareList.value = data;
    };

    // 分类回调事件
    const onChangeSelect = (val: any) => {
      formParams.classify_id = val.id;
      updateShareDoc(formParams);
    };

    const getTagList = async (name?: string) => {
      const data = await useGetTagList(name);
      if (data) {
        classifyTag.value = data;
      }
    };

    const beforeClose = () => {
      dialogVisible.value = false;
    };

    // 操作分享弹窗
    const handleShareDialog = (type: "linkShare" | "openShare" | "select") => {
      if (type === "linkShare") {
        link_share.value = !link_share.value;
      } else if (type === "openShare") {
        open_share.value = !open_share.value;
      } else if (type === "select") {
        shareArtical();
      }
      return true;
    };

    return {
      tempObj,
      formParams,
      onSubmit,
      handleShareDialog,
      beforeClose,
      getTagList,
      isShareSidebar,
      onChangeSelect,
      columnShareList,
      getShareColumnList,
      shareLoading,
      onEvent,
      sidebar,
      articleId,
      toggleEditorFullScreen,
      onlineMembers,
      iframeHost,
      onDownload,
      visible,
      show,
      goBack,
      title,
      onUpdateDocTitle,
      permission,
      onApplyPermission,
      isShowContainer,
      toggleFullScreen,
      dropList,
      handleMenuItem,
      handleShare,
      dialogVisible,
      dialogShadow,

      selectPerson,
      keywordTreeRef,
      filterText,
      handleAddPeople,
      open_share,
      link_share,
      classifyTag,
      hasPermissOpt,
      copyLink,
      CLASSIFY,
      LINKSHARE,
      PUBLICSHARE,
      creator,
      status
    };
  }
});
